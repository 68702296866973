import React, { useState } from "react";
import { handleEmailSubmission } from "../../utils";

export const CommingSoon = () => {
  const [email, setEmail] = useState("");
  return (
    <div className="min-h-screen flex items-center justify-center bg-background">
      <div class="max-w-2xl w-full px-4">
        <h1 class="text-4xl text-primary font-bold text-center mb-8">
          Coming Soon!
        </h1>
        <p class="text-lg text-primary text-center mb-12">
          Our website is under construction. We'll be back soon!
        </p>
        <div className=" pt-8 md:pt-2 text-primary">
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Add this line to prevent the default form submission
              handleEmailSubmission(email);
            }}
            className="flex flex-col sm:flex-row"
          >
            <input
              value={email}
              className="w-full p-2 mr-4 rounded-md mb-4 text-background"
              type="email"
              placeholder="Enter email.."
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              type="submit"
              className="p-2 mb-4 min-w-[20%] text-primary  rounded-md bg-customred"
            >
              Notify me
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CommingSoon;
