import React, { useState } from "react";

import { FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { handleEmailSubmission } from "../../utils";

const items = [
  // { name: "Facebook", icon: FaFacebook, link: "https://www.facebook.com/" },
  {
    name: "Instagram",
    icon: FaInstagram,
    link: "https://www.instagram.com/thegeoview",
  },
  { name: "Twitter", icon: FaXTwitter, link: "https://x.com/theGeoView" },
  { name: "Youtube", icon: FaYoutube, link: "https://youtube.com/@theGeoView" },
];

const Footer = ({ onButtonClick }) => {
  const [email, setEmail] = useState("");
  // useEffect(() => {
  //   listAllDocumentsInCollection("site-status");
  // }, []);

  return (
    <div className="w-full mt-24  bg-background text-gray-300 py-y px-2">
      <div className="px-[6%] md:px-[8%] mx-auto flex flex-col md:flex-row  justify-between border-b-2 border-gray-600 py-8">
        <div
          onClick={onButtonClick}
          className="cursor-pointer md:flex md:items-end justify-center border-primary md:border-background border-[2px] md:bg-transparent py-2 px-5 text-center md:text-start rounded-[10px]"
        >
          More About Geoview
        </div>
        <div className=" pt-8 md:pt-2">
          <p className="font-bold uppercase">Subscribe to our newsletter</p>
          <p className="py-4">
            The latest news, articles, and resources, sent to your inbox weekly.
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Add this line to prevent the default form submission
              handleEmailSubmission(email);
              setEmail("");
            }}
            className="flex flex-col sm:flex-row"
          >
            <input
              value={email}
              className="w-full p-2 mr-4 rounded-md mb-4 text-background"
              type="email"
              placeholder="Enter email.."
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              type="submit"
              className="p-2 mb-4 text-primary  rounded-md bg-customred"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>

      <div className="flex flex-col px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
        <p className="py-4">2023 @Geoview, All rights reserved</p>
        <div className="flex justify-around sm:w-[300px] pt-4 text-2xl">
          {items.map((x, index) => {
            return (
              <x.icon
                onClick={() => {
                  window.open(x.link, "_blank");
                }}
                key={index}
                className="hover:text-white"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Footer;
