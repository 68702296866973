import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Home from "../pages/Home";
import CustomPortal from "../components/Portal";
import AboutUs from "../components/aboutUs";

const FooterLayout = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [portal, setPortal] = useState(false);
  const handleButtonClick = () => {
    // Update the boolValue when the button is clicked
    setPortal(!portal);
  };
  console.log(portal);
  return (
    <div>
      <CustomPortal
        show={portal}
        closeModel={() => setPortal(false)}
        children={<AboutUs />}
        // Fix: Pass a function to closeModel
      ></CustomPortal>
      <Home />
      {isHomePage && <Footer onButtonClick={handleButtonClick} />}
    </div>
  );
};

export default FooterLayout;
