export const TrackerReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_MARKERLIST":
      return { ...state, markerlist: action.payload };
    case "UPDATE_MARKERLIST_ORDER":
      return { ...state, markerListOrder: action.payload };
    case "UPDATE_UNIQUETAGLIST":
      return { ...state, uniquetaglist: action.payload };
    case "UPDATE_SEARCH_QUERY":
      return { ...state, searchquery: action.payload };
    case "UPDATE_SELECTED_TAG":
      return { ...state, searchquery: "", seletedtag: action.payload };
    case "UPDATE_SELECTED_DATE":
      return { ...state, markerListOrder: "none", seleteddate: action.payload };
    case "CLEAR_FILTERS":
      return { searchquery: "", seletedtag: "" };
    default:
      return state;
  }
};
