import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";

import Tracker from "./pages/Tracker";
import Trending from "./pages/Trending";
import FooterLayout from "./layouts/footerlayout";
import { useEffect, useState } from "react";
import { collection, doc, getDoc, getFirestore } from "firebase/firestore";

import CommingSoon from "./components/comingSoon/ComingSoon";

function App() {
  const [isLive, setIsLive] = useState(true);
  useEffect(() => {
    // listAllDocumentsInCollection("site-status");
    const fetchSiteStatus = async () => {
      const db = getFirestore();
      const siteStatusDocRef = doc(collection(db, "site-status"), "status");

      try {
        const docSnapshot = await getDoc(siteStatusDocRef);
        if (docSnapshot.exists()) {
          const { live } = docSnapshot.data();
          setIsLive(live);
          console.log("is live :", isLive);
        }
      } catch (error) {
        console.error("Error fetching site status:", error);
      }
    };

    fetchSiteStatus();
  }, [isLive]);
  return (
    <div className="h-full  bg-background w-full">
      <BrowserRouter>
        {isLive && (
          <div className="w-full sticky top-0 z-[30]">
            <Navbar />
          </div>
        )}
        <Routes>
          {isLive ? (
            <>
              <Route path="/" element={<FooterLayout />} />
              <Route path="/Trending" element={<Trending />} />
              <Route path="/Tracker/:paramName" element={<Tracker />} />
            </>
          ) : (
            // Render an offline message or fallback UI
            <Route path="/" element={<CommingSoon />} />
          )}
        </Routes>
      </BrowserRouter>
    </div>
    // <div className="h-full  bg-background w-full    ">
    //   <BrowserRouter>
    //     <div className="w-full sticky top-0 z-[30]  ">
    //       <Navbar />
    //     </div>
    //     <Routes>
    //       <Route path="/" element={<FooterLayout />}></Route>
    //       <Route path="/Trending" element={<Trending />}></Route>
    //       <Route path="/Tracker/:paramName" element={<Tracker />}></Route>
    //     </Routes>
    //   </BrowserRouter>
    // </div>
  );
}

export default App;
