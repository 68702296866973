import { useEffect, useState } from "react";
import source from "../../assets/icons/source.svg";
import { TwitterVideoEmbed } from 'react-twitter-embed';

function formatTimestamp(timestamp) {
  const date = new Date(timestamp.seconds * 1000); // Convert to milliseconds

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const meridiem = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format

  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return `${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${meridiem} ${formattedDate}`;
}

const TrendingTile = ({ trendingtile }) => {
  const [validImage, setValidImage] = useState(true);

  useEffect(() => {
    const checkImageValidity = async () => {
      try {
        const response = await fetch(trendingtile.imageUrl);
        setValidImage(response.ok);
      } catch (error) {
        setValidImage(false);
      }
    };

    if (trendingtile.imageUrl !== "") {
      checkImageValidity();
    }
  }, [trendingtile]);

  return (
    <div className="flex w-full h-full border-b-[0.5px] border-primary border-opacity-40 bg-background md:py-10 py-3 items-center justify-between gap-5 md:gap-10">
      <div className="post  flex flex-col md:pr-[20%] w-full ">
        <text className="text-[4vw] md:text-lg font-normal py-5">
          {trendingtile.title}
        </text>
        {trendingtile.imageUrl === "" ||
          (trendingtile.imageUrl !== null && (
            <img
              onClick={() => {
                console.log(trendingtile.imageUrl);
              }}
              className="w-full aspect-video object-fill rounded-lg"
              alt=""
              src={trendingtile.imageUrl}
            ></img>
          ))}

            {trendingtile.twitterVdoId === "" ||
          (trendingtile.twitterVdoId !== null && (

            <div className="container mx-auto px-4">
               <TwitterVideoEmbed   Id={trendingtile.twitterVdoID}/>
              </div>
           
          ))}
        <div className="flex  justify-between py-2">
          <text className="text-sm md:text-lg font-extralight">
            {formatTimestamp(trendingtile.dateandtime)}
          </text>
          <span className="flex gap-3">
            {trendingtile.sourceUrl !== "" && (
              <a
                // onClick={console.log(trendingtile.sourceUrl)}
                className=""
                target="_blank"
                href={trendingtile.sourceUrl && trendingtile.sourceUrl}
                rel="noreferrer"
              >
                <img src={source} className="h-6 px-5" alt=""></img>
              </a>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TrendingTile;
