function formatTimestamp(timestamp) {
  const date = new Date(timestamp.seconds * 1000); // Convert to milliseconds

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const meridiem = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format

  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return `${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${meridiem} ${formattedDate}`;
}
const ReadMore = ({ marker }) => {
  return (
    <ul className="flex flex-col h-full item-start overflow-y-auto">
      <div className="flex md:flex-row flex-col h-full w-full ">
        <img
          className="md:h-20 md:w-20 h-10 w-10"
          src={marker.markerIconUrl}
          alt="marker"
        ></img>
        <div className="flex flex-col pt-3 md:p-5 p-1  w-full justify-between">
          <div>
            <h2 className=" text-sm md:text-2xl font-bold">{marker.title}</h2>
            <text className="flex py-2 text-xs md:text-base  text-gray-500">
              {formatTimestamp(marker.dateandtime)}
            </text>

            {marker.desc !== "" && (
              <p className="md:text-lg text-xs md:py-5 ">{marker.desc}</p>
            )}

            {marker.videoUrl !== "" && (
              <video
                className="aspect-video w-full"
                controls
                src={marker.videoUrl}
              ></video>
            )}
            <img
              alt=""
              className="text-blue-600 py-3 object-cover w-full"
              src={marker.imageUrl}
            ></img>
          </div>
          {marker.sourceUrl !== null ? (
            <a
              className=" text-base py-2 px-4 my-1 w-fit bg-black text-white rounded-md md:rounded-lg"
              target="_blank"
              href={marker.sourceUrl}
              rel="noreferrer"
            >
              Source
            </a>
          ) : null}
        </div>
      </div>
    </ul>
  );
};

export default ReadMore;
