// import ReactDOM from "react-dom";

// const CustomPortal = ({ show, closeModel, children }) => {
//   if (!show) return null;

//   return ReactDOM.createPortal(
//     <div className="absolute z-20 h-full w-[100%] flex items-center justify-center">
//       <div
//         className="overlay absolute z-30 h-full w-[100%] bg-black bg-opacity-50"
//         onClick={closeModel}
//       ></div>
//       <div className="content absolute z-40 top-[20%] md:h-[75%] h-fit md:w-[60%] w-[80%] bg-white p-5 rounded-lg flex">
//         <button
//           onClick={closeModel}
//           className="absolute top-0 right-2 font-bold  p-2 text-black"
//         >
//           X
//         </button>
//         {children}
//       </div>
//     </div>,
//     document.getElementById("portal")
//   );
// };

// export default CustomPortal;
import ReactDOM from "react-dom";

const CustomPortal = ({ show, closeModel, children }) => {
  if (!show) return null;

  return ReactDOM.createPortal(
    <div className="fixed z-20 top-0 left-0 h-screen w-screen flex items-center justify-center">
      <div
        className="overlay fixed z-30 h-full w-full bg-black bg-opacity-50"
        onClick={closeModel}
      ></div>
      <div className="content fixed z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-5 rounded-lg flex flex-col items-center">
        <button
          onClick={closeModel}
          className="absolute top-0 right-2 font-bold p-2 text-black"
        >
          X
        </button>
        {children}
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default CustomPortal;
