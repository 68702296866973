import { formatTimestamp } from "../../utils";

const TrackerTile = ({ marker }) => {
  return (
    <div className="w-full   px-[1vw] bg-[#EEEEEE]   flex justify-around items-center md:py-1 py-1 rounded-md ">
      <img
        className="rounded-full md:w-[3.5vw] w-[12vw]   bg-primary  "
        src={marker.markerIconUrl}
        alt="icon"
      ></img>
      <div className="flex flex-col h-full w-full ">
        <div className="flex items-center justify-between grow px-[4vw] md:px-[1vw]">
          <text
            // onClick={setPortal}
            className="flex h-full w-full items-end text-[4vw] md:text-[1vw]  font-normal pt-2"
          >
            {marker.title}
          </text>
          {/* <a className="h-8 w-8" href={marker.link} target="_blank">
            <img alt="" src={source} className="h-8 "></img>
          </a> */}
        </div>
        <div className="flex text-[3vw] md:text-[1vw]  text-[#A9A9A9] justify-end ">
          {formatTimestamp(marker.dateandtime)}
        </div>
      </div>
    </div>
  );
};

export default TrackerTile;
