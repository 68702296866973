// export function getFullDateString(date) {
//   // Convert to milliseconds

//   // const hours = date.getHours();
//   // const minutes = date.getMinutes();
//   // const meridiem = hours >= 12 ? "pm" : "am";
//   // const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format

//   const options = { year: "numeric", month: "short", day: "numeric" };
//   const formattedDate = date.toLocaleDateString("en-US", options);

//   return `  ${formattedDate}`;
// }
import { collection, addDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

export function getFullDateString(date) {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return `  ${formattedDate}`;
}
export function formatTimestamp(timestamp) {
  const date = new Date(timestamp.seconds * 1000); // Convert to milliseconds
  const now = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = now - date;

  // Convert milliseconds to seconds, minutes, hours, and days
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  // Output the time difference in a human-readable format
  if (days < 1) {
    if (hours < 1) {
      return `${minutes} minutes ago`;
    } else {
      return `${hours} hours ago`;
    }
  } else if (days < 7) {
    return `${days} days ago`;
  } else if (months < 1) {
    const weeks = Math.floor(days / 7);
    return `${weeks} weeks ago`;
  } else {
    // Format the original timestamp as a fallback
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
}

//////////////////////////////////////////////
export function extractAndSortTags(data) {
  const tagFrequency = {};

  // Count tag occurrences
  data.forEach((entry) => {
    entry.tags.forEach((tag) => {
      const cleanedTag = tag.replace("#", ""); // Remove '#' symbol
      if (tagFrequency[cleanedTag]) {
        tagFrequency[cleanedTag]++;
      } else {
        tagFrequency[cleanedTag] = 1;
      }
    });
  });

  // Convert tagFrequency object into an array of objects for sorting
  const sortedTags = Object.keys(tagFrequency)
    .map((tag) => ({ tag, frequency: tagFrequency[tag] }))
    .sort((a, b) => b.frequency - a.frequency);

  return sortedTags.map((tagObj) => tagObj.tag);
}
export function filterByTag(data, selectedTag) {
  return data.filter((entry) => entry.tags.includes(`#${selectedTag}`));
}
export function filterBySearchQuery(data, searchQuery) {
  return data.filter((entry) => {
    const { desc, title, tags } = entry;

    const descIncludesQuery = desc
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const titleIncludesQuery = title
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const tagsIncludeQuery = tags.some((tag) =>
      tag.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return descIncludesQuery || titleIncludesQuery || tagsIncludeQuery;
  });
}
/////////////////////////////
export const filterDataByDate = (data, selectedDate) => {
  // Convert the selected date to a timestamp if needed
  const selectedTimestamp = selectedDate.setHours(0, 0, 0, 0); // Set time to midnight

  // Filter the data based on the selected date
  const filteredData = data.filter((item) => {
    // Assuming 'dateandtime' is in a similar timestamp format
    const itemTimestamp = new Date(item.dateandtime.seconds * 1000);
    itemTimestamp.setHours(0, 0, 0, 0); // Set time to midnight

    // Compare the timestamps
    return itemTimestamp.getTime() === selectedTimestamp;
  });

  return filteredData;
};
// Import your Firebase configuration

export const handleEmailSubmission = async (email) => {
  try {
    // Validate email (you can add more validation if needed)
    if (!email || !email.trim()) {
      throw new Error("Please enter a valid email address.");
    }

    // Add the email to the 'newsletter-mails' collection in Firebase
    const newsletterRef = collection(db, "newslater-mails");
    await addDoc(newsletterRef, { email });

    // Return success message
    return "Thank you for subscribing!";
  } catch (error) {
    console.error("Error adding email to collection:", error);

    // Log the specific Firebase error message
    if (error.code && error.message) {
      console.error("Firebase error code:", error.code);
      console.error("Firebase error message:", error.message);
    }

    throw new Error("An error occurred. Please try again.");
  }
};

export const listAllDocumentsInCollection = async (collectionName) => {
  try {
    const collectionRef = collection(db, collectionName);
    const querySnapshot = await getDocs(collectionRef);
    querySnapshot.forEach((doc) => {
      console.log("Document ID:", doc.id);
      console.log("Document data:", doc.data());
    });
  } catch (error) {
    console.error("Error listing documents:", error);
  }
};
