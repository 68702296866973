import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

import TrackerTile from "../components/TrackerTile/TrackerTile";
import { Trackerstate } from "../context/TrackerContext";
import CustomPortal from "../components/Portal";
import ReadMore from "../components/ReadMoreContent";
import DatePicker from "../components/DatePicker";

import CalenderIcon from "../assets/icons/calender.svg";
import { filterDataByDate, formatTimestamp } from "../utils";

const TrackerPage = () => {
  let { paramName } = useParams();
  const {
    state: { markerlist, markerListOrder, seleteddate },
    dispatch,
  } = Trackerstate();
  const [currentMarker, setCurrentMarker] = useState(null);
  const [portal, setPortal] = useState(false);
  const [sortsection, setSortSection] = useState(false);
  const [, setLoading] = useState(true);

  const mapRef = useRef();

  function handleOpenSort() {
    setSortSection(!sortsection);
  }

  const compareMarkers = (a, b) => {
    const dateA = a.dateandtime.seconds;
    const dateB = b.dateandtime.seconds;

    // Toggle between ascending and descending order based on markerListOrder
    const comparison =
      markerListOrder === "newest"
        ? dateB - dateA
        : markerListOrder === "oldest"
        ? dateA - dateB
        : 0;

    return comparison;
  };
  const sortedMarkersbyorder = [...markerlist].sort(compareMarkers);
  const sortedMarkers = filterDataByDate(sortedMarkersbyorder, seleteddate);

  function getDatabaseName() {
    switch (paramName) {
      case "ConflictTracker":
        return "conflict-markers";
      case "MilitaryTracker":
        return "military-markers";
      case "ProtestTracker":
        return "protest-markers";
      // Add more cases as needed
      default:
        return "";
    }
  }

  const fetchPost = async () => {
    await getDocs(collection(db, getDatabaseName())).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
      }));
      dispatch({
        type: "UPDATE_MARKERLIST",
        payload: newData,
      });

      setLoading(false); // Set loading to false once data is fetched
    });
  };

  useEffect(() => {
    fetchPost();
  }, [paramName]);
  console.log(markerlist);
  return (
    <div className="relative flex flex-col h-full w-full ">
      <h1 className="text-primary text-4xl w-fit font-bold mx-[2%] mb-[1.5%] mt-[2%] border-b-[0.3vh] md:border-b-[0.5vh]  pb-2 border-customred ">
        {paramName.replace(/([A-Z])/g, " $1").trim()}
      </h1>
      <div className="flex flex-col md:flex-row h-full  md:h-[70vh]  w-screen md:px-[2%] px-[3%] md:py-[1vh]  md:gap-[2%] md:mb-[18vh]">
        <div className="flex md:h-full h-[40vh] w-full  md:w-[70vw] bg-background text-primary py-[2vh] md:py-0  ">
          <div className="w-screen h-full  z-0  bg-black md:rounded-md rounded-sm overflow-hidden ">
            <MapContainer
              ref={mapRef}
              zoomControl={false}
              center={[20, 80]}
              zoom={1}
              minZoom={2}
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {sortedMarkers.length > 0 &&
                markerlist.map((marker, index) => {
                  const isValidMarker =
                    typeof marker.latitude === "number" &&
                    typeof marker.longitude === "number" &&
                    !isNaN(marker.latitude) &&
                    !isNaN(marker.longitude) &&
                    marker.latitude !== undefined &&
                    marker.longitude !== undefined &&
                    marker.markerIconUrl;
                  return isValidMarker ? (
                    <Marker
                      key={index}
                      position={[marker.latitude, marker.longitude]}
                      icon={L.icon({
                        iconUrl: marker.markerIconUrl,
                        iconSize: [40, 40],
                      })}
                    >
                      <Popup>
                        <div className="flex flex-col items-start justify-center ">
                          <text className="flex  md:py-2 py-0">
                            {formatTimestamp(marker.dateandtime)}
                          </text>

                          <h1 className="md:text-[16px] text-xs font-bold py-2  ">
                            {marker.title}
                          </h1>
                          <button
                            onClick={() => {
                              setPortal(true);
                              setCurrentMarker(marker);
                            }}
                            className="rounded-md bg-slate-900 text-white p-2 ml-[-3px]"
                          >
                            Read more
                          </button>
                        </div>
                      </Popup>
                    </Marker>
                  ) : null;
                })}
            </MapContainer>
          </div>
        </div>
        <div className="flex flex-col  bg-primary md:h-[100%] h-[50vh] px-[2%] md:px-0 w-full text-background  rounded-md md:py-3 py-2 mb-[2vh]  ">
          <div className="relative flex justify-between ">
            <text className="whitespace-nowrap text-base  md:text-lg md:mx-7 mx-[2vw]  my-[1vh] border-b-[0.3vh] md:border-b-[0.3vh] md:py-1 border-customred w-fit  font-normal">
              Live News
            </text>
            <button
              onClick={handleOpenSort}
              className="text-black text-base px-[10%] font-semibold"
            >
              <img src={CalenderIcon} alt="calender" />
            </button>
          </div>

          <DatePicker
            shortVersion={sortsection}
            onButtonClick={handleOpenSort}
          />

          {sortedMarkers.length > 0 && (
            <div className=" flex flex-col w-full h-full md:px-5 px-2 overflow-y-scroll gap-3 md:py-5 py-3">
              {sortedMarkers.map((marker, index) => {
                const isValidMarker =
                  marker.latitude !== undefined &&
                  marker.longitude !== undefined &&
                  marker.markerIconUrl;
                return isValidMarker ? (
                  <div
                    onClick={() => {
                      setCurrentMarker(marker); // Set the currentMarker state
                      setPortal(true);
                    }}
                    key={index}
                  >
                    <TrackerTile marker={marker}></TrackerTile>
                  </div>
                ) : null;
              })}
            </div>
          )}
        </div>
      </div>

      <CustomPortal
        show={portal}
        closeModel={() => setPortal(false)} // Fix: Pass a function to closeModel
      >
        <ReadMore marker={currentMarker}></ReadMore>
      </CustomPortal>
    </div>
  );
};

export default TrackerPage;