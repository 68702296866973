import { TwitterTimelineEmbed } from "react-twitter-embed";
import conflict from "../assets/conflict.png";
import protest from "../assets/protest.png";
import military from "../assets/military.png";
import globe from "../assets/globe.svg";
// import mouse from "../assets/icons/mouse.svg";
import { Link } from "react-router-dom";
// import { Trackerstate } from "../context/TrackerContext";

const Home = () => {
  // const {
  //   state: { activetracker },
  //   dispatch,
  // } = Trackerstate();
  const trackeventlist = [
    { icon: conflict, title: "Conflict Tracker" },
    { icon: protest, title: "Protest Tracker" },
    { icon: military, title: "Military Tracker" },
  ];
  return (
    // <div className="App bg-background h-full  px-[2%] max-w-[1200px] w-full mx-auto">
    <div className="App bg-background h-full  px-[6%] md:px-[8%]  w-full mx-auto">
      <div className="relative w-full h-full flex  md:py-16 py-0">
        <div className=" w-full flex flex-col md:gap-10 gap-5 py-8 ">
          <h1 className="text-white tracking-wider md:text-[80px] text-[45px] leading-[50px] md:leading-[100px] font-bold ">
            Unfolding
          </h1>
          <h1 className="text-white tracking-wider md:text-[80px] text-[45px] leading-[50px] md:leading-[100px] font-bold ">
            <span className="text-customred">Geo</span>politika
          </h1>
          <p className="text-xl  md:text-lg md:leading-[35.2px] font-normal text-white md:w-[55%] w-[80%]">
            Navigating the Changing Geopolitical Landscape
          </p>

          {/* <span className="md:flex gap-2 text-base font-medium text-white py-10 hidden ">
            <span>
              <img src={mouse} alt="mouse" className="w-4"></img>
            </span>
            Scroll to see more sections
          </span> */}
        </div>
        <img
          src={globe}
          alt="globe SVG"
          // className="md:w-[35%] w-[35%] right-3 top-[27%] absolute md:relative md:flex"
          className="md:w-[35%] hidden md:flex"
        ></img>
      </div>
      <div className=" md:py-20 py-8">
        <h1 className="text-primary font-extrabold text-center md:text-5xl text-3xl ">
          Track The Global Events
        </h1>
        <div className="flex md:flex-row flex-col gap-10 items-center justify-center md:py-20 py-10  ">
          {trackeventlist.map((tile) => {
            return (
              <Link
                end
                to={`/Tracker/${tile.title.replace(/\s+/g, "")}`}
                onClick={() => {}}
                className="flex flex-col items-center justify-center md:w-[30%] w-full h-full md:h-[300px] bg-card1 hover:bg-[#161514] p-[5%]   "
              >
                <img className={`w-32`} alt={""} src={tile.icon}></img>
                <text className="text-2xl text-primary text-center md:pb-0 mb-[12%]">
                  {tile.title}
                </text>
              </Link>
            );
          })}

          {/* Embed Twitter timeline in a card */}
        </div>
      </div>
      <div className="flex flex-row-reverse justify-between">
        <div className="">
          <div className="card-body">
            <h5 className="card-title">Twitter Timeline</h5>
            <div className="twitter-timeline-container ">
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="theGeoView"
                options={{ height: 500, width: 350, theme: "dark" }}
              />
            </div>
          </div>
        </div>
        <p className=" hidden  text-primary font-extrabold  md:text-5xl text-3xl md:flex text-start items-center justify-center  px-10 py-5">
          Follow Us On Twitter
        </p>
      </div>
    </div>
  );
};

export default Home;
