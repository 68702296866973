const AboutUs = () => {
  return (
    <div className="flex flex-col h-full item-start overflow-y-auto md:w-[700px] w-[280px] ">
      {" "}
      <text className="text-base md:text-2xl font-normal ">About Us</text>
      <text className="md:text-base text-sm py-3 pl-5">
        In a world saturated with information, deciphering the events that shape
        our lives can be overwhelming. Geoview cuts through the noise. We're a
        geopolitical news platform focused on curating, visualizing, and
        explaining world events. Stay informed, understand the connections, and
        explore the stories impacting our global community.
      </text>
    </div>
  );
};

export default AboutUs;
