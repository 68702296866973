import React, { useReducer } from "react";

import "leaflet/dist/leaflet.css";
import { createContext, useContext } from "react";
import { TrackerReducer } from "./TrackerReducer";

const TrackerContext = createContext();

const UseTrackerContext = ({ children }) => {
  const [state, dispatch] = useReducer(TrackerReducer, {
    markerlist: [],
    markerListOrder: "none",
    uniquetaglist: [],
    searchquery: "",
    seletedtag: "",
    seleteddate: new Date(),
  });

  return (
    <TrackerContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </TrackerContext.Provider>
  );
};

export const Trackerstate = () => {
  return useContext(TrackerContext);
};

export default UseTrackerContext;
