import React from "react";
import ArrowForward from "../assets/icons/arrowForward.svg";
import { Trackerstate } from "../context/TrackerContext";
import { getFullDateString } from "../utils";
import DatePickerComponent from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({ shortVersion, onButtonClick }) => {
  const {
    state: { seleteddate },
    dispatch,
  } = Trackerstate();
  // const [currentFocusedInput, setCurrentFocusedInput] = useState(0);
  // const [selectedDate, setSelectedDate] = useState(seleteddate); // Initial date: January 3, 2024

  // const getDaysInMonth = (year, month) =>
  //   new Date(year, month + 1, 0).getDate();
  // const getValidMonthRange = () => Array.from({ length: 12 }, (_, i) => i + 1);
  // const getValidDayRange = () =>
  //   Array.from(
  //     {
  //       length: getDaysInMonth(
  //         selectedDate.getFullYear(),
  //         selectedDate.getMonth()
  //       ),
  //     },
  //     (_, i) => i + 1
  //   );

  function incrementDate() {
    const newDate = new Date(seleteddate);
    newDate.setDate(newDate.getDate() + 1);
    dispatch({
      type: "UPDATE_SELECTED_DATE",
      payload: newDate,
    });
  }

  function decrementDate() {
    const newDate = new Date(seleteddate);
    newDate.setDate(newDate.getDate() - 1);
    dispatch({
      type: "UPDATE_SELECTED_DATE",
      payload: newDate,
    });
  }

  return (
    <>
      {shortVersion ? (
        <div className=" w-[90%] items-center justify-center mx-auto bg-[#EEEEEE]   ">
          {" "}
          <DatePickerComponent
            className="bg-[#EEEEEE]  w-full text-base text-background font-normal px-5 py-1"
            selected={seleteddate}
            onChange={(date) => {
              dispatch({
                type: "UPDATE_SELECTED_DATE",
                payload: date,
              });
              onButtonClick();
            }}
          />
        </div>
      ) : (
        <span className="px-5">
          <div className="bg-[#EEEEEE]  w-full  h-10 col-span-3 rounded-sm text-neutral-600 text-base font-extrabold flex items-center justify-between px-5">
            <button
              onClick={() => {
                decrementDate(seleteddate, 1);
              }}
            >
              <img className="rotate-180" alt="prev" src={ArrowForward} />
            </button>
            <text className="w-full text-base text-background text-center font-normal">
              {getFullDateString(seleteddate)}
            </text>
            <button
              onClick={() => {
                incrementDate(seleteddate, 1);
              }}
            >
              {" "}
              <img alt="next" src={ArrowForward} />
            </button>
          </div>
        </span>
      )}
    </>
  );
};

export default DatePicker;
