import { useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";

import logo from "../../assets/geoviewlogo.png";
import menu from "../../assets/icons/menu.svg";
import plusicon from "../../assets/icons/plusicon.svg";

const Navbar = () => {
  const { paramName } = useParams();
  const [open, setOpen] = useState(false);
  const [nav, setNav] = useState(false);

  // Function to determine if the current route is the home page

  const handleNav = () => {
    setNav(!nav);
  };
  const location = useLocation();
  const isHomePage =
    location.pathname === "/Tracker/ConflictTracker" ||
    location.pathname === "/Tracker/MilitaryTracker" ||
    location.pathname === "/Tracker/ProtestTracker";
  console.log(isHomePage);
  const navbaritem = [
    "Home",
    "Trending",
    "Conflict Tracker",
    "Military Tracker",
    "Protest Tracker",
  ];
  console.log(paramName);
  return (
    <div className="w-full bg-background  border-b-[0.1vw] border-solid border-[#FAFAFA] border-opacity-20 ">
      <ul
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        className={`absolute z-60 text-primary bg-[#292929] right-[4vw] top-[63px] w-40 py-2 mt-2 rounded-lg shadow-xl gap-[1px] ${
          open ? "block" : "hidden"
        }`}
      >
        {["Conflict Tracker", "Military Tracker", "Protest Tracker"].map(
          (nav) => {
            return (
              <NavLink
                to={
                  nav.toLowerCase().includes("tracker")
                    ? `/Tracker/${nav.replace(/\s+/g, "")}`
                    : nav === "Home"
                    ? "/"
                    : nav
                }
                onClick={() => {
                  setOpen(false);
                }}
                className={
                  "flex w-full items-center px-5 py-2 text-sm hover:bg-[#2e2e2e]"
                }
              >
                {nav}
              </NavLink>
            );
          }
        )}
      </ul>
      <div className=" flex justify-between  items-center md:px-[5vw] md:py-2 px-[2vw]  w-full mx-auto">
        <img
          className="md:w-[12vw] w-[30vw] md:my-[-12vw] my-[-6%] md:ml-[-2%] text-white"
          src={logo}
          alt="The Geoview"
        ></img>
        <div onClick={handleNav} className="block  md:hidden w-[10%] pr-[4%]">
          {nav ? (
            <img className=" rotate-45" src={plusicon} alt=""></img>
          ) : (
            <img className=" " src={menu} alt=""></img>
          )}
        </div>
        {nav && (
          <div
            onClick={handleNav}
            className={`md:hidden z-60 fixed  h-[700%] w-screen bg-opacity-75 bg-background`}
          ></div>
        )}
        <div
          className={`z-40 md:hidden fixed flex flex-col h-full w-[70%] border-r border-r-gray-900 text-primary bg-background px-[2%] text-2xl
           ${
             nav
               ? "   left-0 top-0 w-[75%]  ease-in-out duration-500"
               : " ease-in-out duration-500 left-[-100%] "
           }`}
        >
          <img
            className="flex items-center justify-center md:w-[12vw] w-[30vw]  my-[-7%] ml-[2%] "
            src={logo}
            alt="The Geoview"
          ></img>
          {navbaritem.map((nav) => {
            return (
              <NavLink
                onClick={handleNav}
                to={
                  nav.toLowerCase().includes("tracker")
                    ? `/Tracker/${nav.replace(/\s+/g, "")}`
                    : nav === "Home"
                    ? "/"
                    : nav
                }
                className={({ isActive }) =>
                  ` text-primary font-bold pl-[6%] py-[2%] border-primary border-opacity-40 border-b-[1px] text-[6vw] ${
                    isActive ? " hidden" : " block"
                  }`
                }
              >
                {nav}
              </NavLink>
            );
          })}
        </div>
        <div className="md:flex gap-8 md:gap-5 hidden  justify-between text-white text-lg font-normal  whitespace-nowrap">
          {["Home", "Trending"].map((nav) => {
            return (
              <NavLink
                to={
                  nav.toLowerCase().includes("tracker")
                    ? `/Tracker/${nav.replace(/\s+/g, "")}`
                    : nav === "Home"
                    ? "/"
                    : nav
                }
                className={({ isActive }) =>
                  isActive
                    ? "text-primary rounded-[10px]  bg-customred  md:px-[26px] md:py-[10px] text-[1.5vw] md:text-xl font-bold  my-[2vh] "
                    : "text-primary  rounded-[10px] border-background   md:px-[26px] md:py-[10px] text-[1.5vw] md:text-xl  my-[2vh]  hover:bg-[#232323]"
                }
              >
                {nav}
              </NavLink>
            );
          })}{" "}
          <NavLink
            to={"Tracker/ConflictTracker"}
            onMouseOver={() => setOpen(true)}
            onClick={() => setOpen(!open)}
            onMouseLeave={() => setOpen(false)}
            className={`${
              isHomePage
                ? "text-primary  rounded-[10px] bg-customred px-[4vw] md:px-[26px] md:py-[10px] text-[1.5vw] md:text-xl font-bold  my-[2vh]  "
                : "text-primary  rounded-[10px] border-background  px-[4vw] md:px-[26px] md:py-[10px]  text-[1.5vw] md:text-xl font-normal  my-[2vh] "
            } hover:bg-[#232323]`}
          >
            Trackers
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
