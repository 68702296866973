import { useEffect, useState } from "react";

import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

import TrendingTile from "../components/TrendingTile/TrendingTile";

import searchicon from "../assets/icons/search.svg";

import { Trackerstate } from "../context/TrackerContext";
import { extractAndSortTags, filterBySearchQuery, filterByTag } from "../utils";
import { TwitterTimelineEmbed } from "react-twitter-embed";

const Trending = () => {
  const [inputValue, setInputValue] = useState("");
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const {
    state: { seletedtag, searchquery, uniquetaglist, markerlist },
    dispatch,
  } = Trackerstate();

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log("Input value:", inputValue);
    dispatch({
      type: "UPDATE_SEARCH_QUERY",
      payload: inputValue,
    });
    setInputValue("");
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const fetchPost = async () => {
    await getDocs(collection(db, "trending")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
      }));
      dispatch({
        type: "UPDATE_MARKERLIST",
        payload: newData,
      });
      const uniqueTagsByFrequency = extractAndSortTags(newData);
      dispatch({
        type: "UPDATE_UNIQUETAGLIST",
        payload: uniqueTagsByFrequency,
      });

      console.log(newData);
    });
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (prevScrollPos > currentScrollPos) {
      setVisible(true);
    } else {
      setVisible(false);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);
  // const handleOptionClick = (option) => {};

  useEffect(() => {
    dispatch({
      type: "UPDATE_SELECTED_TAG",
      payload: "trending",
    });

    fetchPost();
  }, []);
  // console.log(uniquetaglist);
  const filteredList = filterBySearchQuery(markerlist, searchquery);

  return (
    <div className=" bg-background h-full   md:px-[8%] px-[3%]  w-full mx-auto text-primary relative">
      {/* <div className="trending relative bg-background h-full   md:px-[8%] px-[3%]  w-full mx-auto text-primary"> */}
      <div className="w-full h-full flex flex-col  ">
        {
          <div
            className={`bg-background fixed  w-full z-20  ${
              visible
                ? "md:top-[60px] top-[65px] transition-all duration-500"
                : "md:top-[-130px] top-[-70px] "
            }   right-0 flex-col justify-between md:pt-7 py-0 md:px-[8%] px-[3%] `}
          >
            <div className="flex items-center justify-between">
              <text className="md:w-full pr-5 md:px-0 md:text-6xl md:font-medium text-[7vw]  py-5 md:flex  ">
                Trending
              </text>
              <form
                onSubmit={handleSubmit}
                className=" relative flex flex-row-reverse bg-background  text-white border-solid border-[1px] border-[#E7E7E7] h-[5vh] md:h-10 md:border-[1px]  w-[50%] rounded-full "
              >
                <div className="w-[30%] flex items-center justify-center text-base font-bold ">
                  <button type="submit">
                    <img src={searchicon} alt=""></img>
                  </button>
                </div>
                <input
                  placeholder="search topics, news, events"
                  value={inputValue}
                  onChange={handleInputChange}
                  className="my-2 px-5 mx-1 w-full bg-transparent outline-none focus:border-transparent text-base  focus:ring-0"
                ></input>
              </form>
            </div>

            <div className="md:max-w-full h-full md:border-b-[0.5px] gap-4  border-primary border-opacity-30  w-full flex  items-center text-lg md:text-3xl whitespace-nowrap overflow-x-scroll py-3 md:py-5 ">
              <div
                className={`cursor-pointer  text-[5vw] md:text-base md:font-normal  py-[0.5vh] md:py-[0.5vh] px-[3vw] md:px-[3vh] rounded-[10px]  ${
                  seletedtag === "trending"
                    ? "bg-customred text-primary"
                    : "bg-primary text-background"
                }`}
                // key={index}
                onClick={() =>
                  dispatch({
                    type: "UPDATE_SELECTED_TAG",
                    payload: "trending",
                  })
                }
              >
                {"trending"}
              </div>
              {uniquetaglist.slice(0, 4).map((text, index) => {
                return (
                  <div
                    className={`cursor-pointer  text-[5vw] md:text-base md:font-normal  py-[0.5vh] md:py-[0.5vh] px-[3vw] md:px-[3vh] rounded-[10px] ${
                      seletedtag === text
                        ? "bg-customred text-primary"
                        : "bg-primary text-background"
                    }`}
                    key={index}
                    onClick={() =>
                      dispatch({
                        type: "UPDATE_SELECTED_TAG",
                        payload: text,
                      })
                    }
                  >
                    {text}
                  </div>
                );
              })}
            </div>
          </div>
        }
        {!visible ? (
          <div className="flex flex-col justify-between md:py-7 py-0">
            <div className="flex items-center justify-between">
              <text className="md:w-full pr-5 md:px-0 md:text-6xl md:font-medium text-[7vw]  py-5 md:flex ">
                Trending
              </text>
              <form
                onSubmit={handleSubmit}
                className=" relative flex flex-row-reverse bg-background  text-white border-solid border-[1px] border-[#E7E7E7] h-[5vh] md:h-10 md:border-[1px]  w-[50%] rounded-full "
              >
                <div className="w-[30%] flex items-center justify-center text-base font-bold ">
                  <button type="submit" onClick={console.log(searchquery)}>
                    <img src={searchicon} alt=""></img>
                  </button>
                </div>
                <input
                  placeholder="search topics, news, events"
                  value={inputValue}
                  onChange={handleInputChange}
                  className="my-2 mx-1 px-5 w-full bg-transparent outline-none focus:border-transparent text-base  focus:ring-0"
                ></input>
              </form>
            </div>

            <div className="md:max-w-full h-full md:border-b-[0.5px] gap-4  border-primary border-opacity-30  w-full flex  items-center text-lg md:text-3xl whitespace-nowrap overflow-x-scroll py-3 md:py-5 ">
              <div
                className={`cursor-pointer  text-[5vw] md:text-base md:font-normal  py-[0.5vh] md:py-[0.5vh] px-[3vw] md:px-[3vh] rounded-[10px] ${
                  seletedtag === "trending"
                    ? "bg-customred text-primary"
                    : "bg-primary text-background"
                }`}
                // key={index}
                onClick={() =>
                  dispatch({
                    type: "UPDATE_SELECTED_TAG",
                    payload: "trending",
                  })
                }
              >
                {"trending"}
              </div>
              {uniquetaglist.slice(0, 4).map((text, index) => {
                return (
                  <div
                    className={`cursor-pointer  text-[5vw] md:text-base md:font-normal  py-[0.5vh] md:py-[0.5vh] px-[3vw] md:px-[3vh] rounded-[10px] ${
                      seletedtag === text
                        ? "bg-customred text-primary"
                        : "bg-primary text-background"
                    }`}
                    key={index}
                    onClick={() =>
                      dispatch({
                        type: "UPDATE_SELECTED_TAG",
                        payload: text,
                      })
                    }
                  >
                    {text}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="w-full md:h-[200px] h-[150px] "></div>
        )}

        {searchquery !== "" && (
          <text className="flex items-center font-bold py-[0.5vh]">
            {"showing results for : "}
            <span
              onClick={() =>
                dispatch({
                  type: "UPDATE_SELECTED_TAG",
                  payload: "trending",
                })
              }
              className=" ml-3 px-3 py-1 bg-primary rounded-2xl text-background"
            >
              {searchquery}
              <span className="text-background ml-6">{" x"}</span>
            </span>
          </text>
        )}
        <div className="w-full md:flex-row flex flex-col h-full">
          <div className="md:w-[65%] w-full h-full  border-primary border-opacity-25 ">
            {markerlist.length < 1 && (
              <div className="h-[70vh] w-full bg-background text-primary flex justify-center items-center ">
                Loading Data...
              </div>
            )}
            {seletedtag === "trending" ? (
              <div>
                {filteredList.length > 0 ? (
                  filteredList.map((trendingtile) => (
                    <TrendingTile
                      key={trendingtile.id}
                      trendingtile={trendingtile}
                    />
                  ))
                ) : (
                  <div className="h-[65vh] w-full bg-background text-primary flex justify-center items-center ">
                    No Data
                  </div>
                )}
              </div>
            ) : (
              filterByTag(markerlist, seletedtag).map((trendingtile) => {
                return (
                  <TrendingTile trendingtile={trendingtile}></TrendingTile>
                );
              })
            )}
          </div>
          {/* whitespace fill */}
          {/* {filterByTag(markerlist, seletedtag).length < 3 && (
            <span className="md:hidden min-h-96 w-full bg-background" />
          )} */}

          <div className="w-[35%]  h-screen">
            <div
              // className={`hidden md:flex sticky${
              className={`hidden md:flex sticky${
                visible ? "top-[50vh]" : "top-[20vh]"
              } w-[90%] mx-auto    my-[5%]`}
            >
              <div className="twitter-timeline-container max-h-[300px] ">
                <TwitterTimelineEmbed
                  sourceType="profile"
                  screenName="theGeoView"
                  options={{ height: 600, width: 350, theme: "dark" }}
                />
              </div>
              {/* <div className="hidden md:flex flex-col md:h-[550px]      bg-[#121212] rounded-[30px]    overflow-y-auto ">
                <text className=" sticky top-0 w-full h-fit  text-xl font-normal bg-[#121212] text-center  px-5 py-6">
                  Top Trending Posts
                </text>
                <div className="">
                  {[
                    "Romania moves toward $6.5 billion F-35 buy",
                    "Romania moves toward $6.5 billion F-35 buy",
                    "Romania moves toward $6.5 billion F-35 buy",
                    "Romania moves toward $6.5 billion F-35 buy",
                    "Romania moves toward $6.5 billion F-35 buy Romania moves toward $6.5 billion F-35 buy",
                  ].map((text) => {
                    return (
                      <div className="w-full  flex   py-2 items-center gap-5 px-2">
                        <img
                          className="w-[30%] aspect-[3/2] p-1 rounded-[10px]"
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAuZf7MWy9cT1MKh4ENoVBEjQ_nGI443MONX9PLTTzcw&s"
                          alt=""
                        ></img>
                        <text className="w-full h-[50px]  whitespace-no-wrap overflow-hidden">
                          {text}
                        </text>
                      </div>
                    );
                  })}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trending;
